import { createSlice } from "@reduxjs/toolkit"


//initial state
//design of the bot
const initialState = {
    design : {
        botName: "Answer bot",
        bgColor: "#5269C9",
        textColor: "#ffffff",
        widgetBgColor: "#6D85EB",
        widgetPosition: "right",
        widgetSideSpacing: 0,
        widgetBottomSpacing: 0,
        themeBgColor: "#ffffff",
        themeTextColor: "#333333",
        messageBgColor: "#EDF1FF",
        botMsgBGColor: "#edf1ff",
        botMsgTextColor: "#333333",
        UserMsgBgColor: "#edf1ff",
        UserMsgTextColor: "#333333",
        chatBublefontFamily: "'Poppins', sans-serif",
        chatBublefontSize: 14,
        sendBtnColor: "#6D85EB",
        botBtnColor: "#C6C6C6",
        botBtnFontFamily: "'Poppins', sans-serif",
        botBtnFontSize: 14,
        selectPowerdBy: "Company",
        enterCompanyName: "Appypie",
        footerPowerdBy: true,
        badge : 'badge1',
        avtarSelect : 'avatar1'
      },
      basicSetting : 'basicSettings'
}


//creating slice
//state -- botDesign
export const botDesignSlice = createSlice({
    name : 'botDesign',
    initialState,
    reducers : {
        updateDesign : (state , action) => {
            state.design[action.payload.property] = action.payload.value
        },
        changeBasicSetting : (state , action) => {
            state.basicSetting = action.payload
        }
    }
})


//exporting actions
export const {updateDesign , changeBasicSetting} = botDesignSlice.actions


//exporting reducers
export default botDesignSlice.reducer