import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    gptDataGlobal : {},
    gptLoader : false,
}



const trainGptSlice = createSlice({
    name : "trainGpt",
    initialState,
    reducers : {

        setGptDataGlobal : (state, action) => {
            state.gptDataGlobal = action.payload
        },
        setGptLoader : (state, action) => {
            state.gptLoader = action.payload
        }
    }
})


export const { setGptDataGlobal , setGptLoader } = trainGptSlice.actions

export default trainGptSlice.reducer