import { add } from "lodash";
import { db } from "../../config/firebase";
import { downloadJson, getCookie, getUserData, updateTemplateinDbManually } from "../../utilities/chatbotMethods";
import { addP, addParentNodeInTemplate, makeNextJson } from "../../utilities/convertTemplateToFlowChart";
import { updateTemplate } from "./botTemplateSlice";

//getting data from firebase
export const getBotFlowChartDataAsyc = async (botId,thunkAPI) => {
return new Promise((resolve, reject) => {
    db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(botId)
        .get().then(async (result) => {
            console.log(result.data())
            const response = result.data()


            console.log(response , ' new response in thunk')

            // const parJson = makeNextJson(response.template)
            // const newFlow = addP(response.template , parJson)
            var templatePara=response.templateTemp&&Object.keys(response.templateTemp).length>0?response.templateTemp:response.template


            console.log(templatePara , ' template para')


            
            var flow = await addParentNodeInTemplate(templatePara.template_One?templatePara.template_One:templatePara)
            // downloadJson(JSON.stringify(flow))

            let custom={"showFooter":true,"footerCompanyName":"Company","companyNameLogo":"Name","footerBgColor":"#F3F6FF","footerTextColor" :"#7E8697","userAvtar":"https://chatimages.appypie.com/site_images/userImg.png","userIconBackgroundColor":"#d2d9fe","chatbotIconBackgroundColor":"#d2d9fe","widgetSize":"large","buttonfontSize":"14","designPage":"default","botTextcolor":"#1d1d1d","designLanguage":"english(en)","messagebubble":"#f3f6ff","widgetPosition":{"side":"8","bottom":"8","position":"right"},"botButtonColor":"#2c3049ff","clientchattextcolor":"#ffffff","widgetBackgroundColor":"#2967f6","headertextcolor":"#ffffff","clientBubbleClr":"#2967f6","sendButtonClr":"#2967f6","chatBotAvtar":"https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar3.svg?alt=media&token=f6319d10-02fb-490c-892a-8b49a553fcc2","textfonts":"roboto","botButtonTextColor":"#FFFFFF","backgroundChatBot":"#FFFFFF","widgetImage":"https://chatimages.appypie.com/64bf9126221d317a8b268a4d/BOTID1712755022753/image_2024_04_18T12_15_44_276Z.png","botButtonText":"lora","headerClr":"#2967f6"}
            let customNew=Object.assign(custom,flow.customisation)
            flow.customisation=customNew
            const template = {
                template_One :flow,
                templateTemp:response.templateTemp&&Object.keys(response.templateTemp).length>0?true:false,
                // templateOne : newFlow,
                chatBotAvtars : response.chatBotAvtars,
                widgetImages : response.widgetImages,
                initialHit:true
            }

            const {email} = getUserData(getCookie("APPYID"))


            localStorage.setItem("email" , email)
            


            
            thunkAPI.dispatch(updateTemplate(template))

            //calling for development purpose only
            //need to be commented
            // updateTemplateinDbManually(flow , "BOTID1723120163348" , localStorage.tablealias , localStorage.agent_id).then(res => {
            //     console.log('manualn template updated successfully');
                
            // })
            // .catch(err => {
            //     console.log(' manual template update failed');
                
            // })
            resolve()
        })
}) 

  
}