import { createSlice  , createAsyncThunk } from '@reduxjs/toolkit'
import { userBots } from '../../server/service'
import { db } from '../../config/firebase'
import { getDashboardDataAsync , toggleBotStatusAsync } from './userBotsThunk'
//@DEF - getting the data from the service file and initializing the state
//@VARIABLE - userBots
//@TYPE - array

const initialState = {
  userBots : null
}




//thunk
export const getDashboardData = createAsyncThunk('userBots/getDashboardData' , getDashboardDataAsync)
export const toggleBotStatus = createAsyncThunk('userBots/toggleBotStatus' , toggleBotStatusAsync)



// const fetchUserById = createAsyncThunk(
//   'users/fetchByIdStatus',
//   async (userId: number, thunkAPI) => {
//     const response = await userAPI.fetchById(userId)
//     return response.data
//   }
// )


//creating slice -- userBotsSlice
//contains reducer and actions and actions creators
export const userBotsSlice = createSlice({
  name: 'userBots',
  initialState,
  reducers: {
    // toggleStatus : (state , action) => {      
    //   state.userBots = {
    //     ...state.userBots,
    //     [action.payload] : {
    //       ...state.userBots[action.payload],
    //       status : !state.userBots[action.payload].status
    //     }
    //   }
    // },
    setUserBots : (state , action) => {
        state.userBots = action.payload
    }

  },
  extraReducers : (builder) => {
    // builder.addCase(getDashboardData.fulfilled , (state , action) => {
    //   console.log(action.payload , "payload")
    //   state.userBots = action.payload
    // })
    builder.addCase(toggleBotStatus.fulfilled , (state , action) => {
      console.log(action.payload , "payload")
      state.userBots = {
        ...state.userBots,
        [action.payload.botId] : {
          ...state.userBots[action.payload.botId],
          status : action.payload.newStatus
        }
      }
    })
  }
})

// Action creators are generated for each case reducer function
//export const { increment, decrement, incrementByAmount } = userBotsSlice.actions

export const {toggleStatus , setUserBots} =  userBotsSlice.actions


//exporting reducer -- userBotsReducers
//state -- {userBots : Array}
export default userBotsSlice.reducer