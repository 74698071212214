import React from 'react'

function FormBotSetup() {
  return (
    <div className='scriptCodeSetup' id='myScript2'>
    {`<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=6.0, user-scalable=0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge">
                <meta name="lang" http-equiv="content-language" content="it">
            </head>
            
            <body style="margin: 0">
            <script id="chatbotAppy"  src="${window.location.origin}/widget/newBuild.js?companyId=${localStorage.tablealias}&botId=${localStorage.botId}&agentId=${localStorage.agent_id}&isFullScreen=true"></script>

            </body>
            
            </html>`}
  </div>
  )
}

export default FormBotSetup


// ${window.location.origin}