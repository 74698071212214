import React from 'react'

function WebsiteSetup() {


    //utility function
    const extractHostname = (url) => {
        var hostname
        //find & remove protocol (http, ftp, etc.) and get hostname
        if (url.indexOf('//') > -1) {
          hostname = url.split('/')[2]
        } else {
          hostname = url.split('/')[0]
        }
    
        //find & remove port number
        // hostname = hostname.split(':')[0];
        //find & remove "?"
        // hostname = hostname.split("?")[0];
    
        return 'https://' + hostname + '/'
      }


      //getting host name
      const url_ = extractHostname(window.location.href)



  return (
    <div className='scriptCodeSetup' id='myScript'>
   
    <span>
      
        {'<!-- Appy Chatbot widget, Paste the code in the <body> section --> '}
      
    </span>
    <br />
    {/* {'<script id="appyWidgetInit" src="' +
      url_ +
      
      'widget/loadbuild.js' +
      '?cid=' +
      localStorage.getItem('tablealias') +
      '-' +
      localStorage.getItem('agent_id') +
      '-' +
      
      window.location.href.split('?')[1].split('=')[1] +
      '&amp;name=' +
      'mixBuild' +
      '"></script>'} */}
      {`<script src="${window.location.origin}/widget/loadnew.js?companyId=${localStorage.tablealias}&botId=${localStorage.botId}&agentId=${localStorage.agent_id}"></script>`}
    <br />
    <span>
      {'<!-- /. End Appy chatbot widget code --> '}
    </span>
    <br />
  </div>
  )
}

export default WebsiteSetup