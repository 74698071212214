import React from 'react'
import { useEffect } from 'react';
import "./stepLoader.css";
function StepLoader() {


  
  return (
   <div className="flow-chart-skeleton">
  <div className="step-skeleton" />
  <div className="arrow-skeleton" />
  <div className="step-skeleton" />
  <div className="arrow-skeleton" />
  <div className="step-skeleton" />
  <div className="arrow-skeleton" />
  <div className="step-skeleton" />
  <div className="arrow-skeleton" />
  <div className="step-skeleton" />
</div>

  )
}

export default StepLoader