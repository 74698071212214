import React from 'react'
import { useEffect } from 'react'

function CustomDropdown(props) {


    //destructuring props
    const {options , handleOptionChange, value , imageInCloud , displayIconUrl} = props


    //local states
    const [isOpenMenu, setIsOpenMenu] = React.useState(false)
    const [displayName, setDisplayName] = React.useState('')



    


    const handleDropDownMenu = () => {
		setIsOpenMenu(!isOpenMenu);
	};



    //handler function
    const handleClick = (option) => {
        handleOptionChange(option)
        setIsOpenMenu(false)
    }
  return (
    <div className='dropdown relative'>
    <button onClick={handleDropDownMenu} className={`border rounded-lg p-3 w-full text-left flex items-center justify-between gap-3 ${isOpenMenu ? 'active': ''}`}> {imageInCloud ? <img className="w-5" src={displayIconUrl} /> : <img className="w-5" src={require(`./../../../img/${displayIconUrl}`)} />   }      {value} <span className='arrowright ml-auto'><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path></svg></span></button>
    {isOpenMenu &&	<ul className='bg-white border shadow-xl p-4 px-2 rounded-lg absolute top-100 left-0 w-full z-10'>
      {options.map((option, index) => (
        <li className={`text-sm py-3 px-3 cursor-pointer rounded-lg hover:bg-[#F3F5FF] flex items-center justify-start gap-2 ${value === option.setupType && 'bg-[#F3F5FF]' }`} key={index} onClick={() => handleClick(option)}>
            
            {imageInCloud ? <img className="w-5" src={option.imageUrl} /> : <img className="w-5" src={require(`./../../../img/${option.imageUrl}`)} />   }
            <span>{option.title}</span>
        </li>
      ))}
    </ul>
    }
  </div>
  )
}

export default CustomDropdown