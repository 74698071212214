import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'



//@DEF - getting the data from the data/templates/Inquiry.js file  and initializing the state
//@VARIABLE - inquiry
//@TYPE - object

const initialState = {
    botName : '',
    botType : ''
}





//creating slice -- botTemplateSlice
//contains reducer and actions and actions creators
export const createBotSlice = createSlice({
  name: 'createBot',
  initialState,
  reducers: {
    changeBotName : (state , action) => {
        state.botName = action.payload
    },
    changeBotType : (state , action) => {
        state.botType = action.payload
    }
        
  },
  extraReducers : (builder) => {
    
  }
})



export const {changeBotName , changeBotType} =  createBotSlice.actions


//exporting reducer -- createBotRedeucer
//state -- {createBot : Object}
export default createBotSlice.reducer