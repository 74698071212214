import { createSlice } from "@reduxjs/toolkit"


//initial state
const initialState = {
    innerWidth : window.innerWidth,
    MOBILE_BREAKPOINT : 1024
}


//creating slice
//state -- screen
export const screenSlice = createSlice({
    name : 'screen',
    initialState,
    reducers : {
        updateInnerwidth : (state , action) => {
            state.innerWidth =2000
            //  window.location.origin.includes("chatbotlocal")?"2000":action.payload
        },
        setMobileBreakpoint : (state , action) => {
            state.MOBILE_BREAKPOINT = action.payload
        }
    }

})


//exporting actions
export const {updateInnerwidth , setMobileBreakpoint } = screenSlice.actions


//exporting reducers
export default screenSlice.reducer