import { db } from "../../config/firebase";




export const getInstalledIntegrationsAsync = async () => {
    return db.collection('Connect_List')
    .doc(localStorage.getItem('tablealias'))
    .collection('chatbot')
    .doc(localStorage.getItem("botId"))
    .collection("all_integrations")
    .get().then(
    async coll=>{
      // .get().then(coll=>{
      console.log(coll.docs.length,"collcollcollONSNAPSHOT")
      const connect_installed = []
      let emailTemplates = {}
    if (coll &&coll.docs.length>0){

        console.log(coll , 'coll')
        console.log(coll.docs , 'coll.docs')
      coll.docs.forEach(doc => {
        console.log(doc.id , 'doc.id.installed')
        if (!connect_installed.includes(doc.id)){
          connect_installed.push(doc.id)

          
          if(doc.id == 'appy_email'){
            console.log(doc.data())
            emailTemplates = doc.data()

            
            
          }
          if(doc.id=== "gdrive"){
            connect_installed.push("googlesheets")
            connect_installed.push("google_sheets")
          }

          
          
        }
      });
      return {connect_installed ,emailTemplates }
    }
    else{
        return {connect_installed , emailTemplates}
    }
  
  
 
    
  })
}