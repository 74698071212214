import React from 'react'
import "./SetupLoader.css"

const SetupLoader = () => {
    return (
        <> 
        <div className='flex flex-wrap mt-10'>
            <div className="skeleton flex gap-5 flex-wrap">
                <div className="skeleton-right"> <div className="square" /></div>
                <div className="skeleton-left"><div className="line h17 w-full m10" /></div>
            </div>
        </div>
        </>
    )
}

export default SetupLoader
