
import { useNavigate } from "react-router";
import { db } from "../../config/firebase";
// import { setUserBots } from "./userBotsSlice";
import {setAgentData} from './agentSlice'

//getting data from firebase
export const getAgentDataAsync = async (_,thunkAPI) => {
return new Promise((resolve, reject) => {
    db
  .collection("user_" + localStorage.getItem("tablealias"))
  .doc(localStorage.getItem("agent_id"))
  
  .onSnapshot((doc) => {
    
    // console.log(doc.data() , ' maggiaa')
    if(doc.exists){

      const agentData = doc.data()
      console.log(agentData , ' data of the agent');
      //checing whether to force logout or not
      //if true -  means there has been some change made through accounts.appypie.com
      if(agentData&&agentData.forceLogoutTime){
        // console.log("CHECK FOR FORCE LOGOUT",parseInt(localStorage.getItem("sessionStartTime")),parseInt(user_details.forceLogoutTime))
        if(localStorage.getItem("sessionStartTime")){
        if(parseInt(localStorage.getItem("sessionStartTime"))<parseInt(agentData.forceLogoutTime)){
          
          localStorage.clear();
          // document.cookie = "APPYID='';"
         window.location.href = `https://${window.location.host}/login`
         
          
        }
      }else{
          
          localStorage.clear();
          window.location.href = `https://${window.location.host}/login`
          
  
        }

      }
      
      thunkAPI.dispatch(setAgentData(doc.data()))
      resolve()
      
    
    }else{
      localStorage.clear()
      window.location.href=window.location.origin+"/login";
    }
    // reject(doc)

  }).catch((err) => {
    console.log(err)
    reject(err)
  })
}) 

  
}