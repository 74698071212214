import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function RestrictEditor(props) {

  //initializing navigate
  const navigate = useNavigate()

  const {handlePageChange} = props

  //global states


  //handler function
  const handleBackClick = () => {
    console.log('back clicked')
    // handlePageChange(null)
    navigate('/mobile/flow?did=' + localStorage.getItem('botId'))
  }





  return (
		<div className='h-full w-full items-center flex flex-col gap-5 min-h-screen justify-center p-4 px-6 text-center'>
			<div className='w-20 h-20 flex items-center justify-center bg-[#32373B] rounded-full text-white'>
			<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M376 186h-20v-40c0-55-45-100-100-100S156 91 156 146v40h-20c-22.002 0-40 17.998-40 40v200c0 22.002 17.998 40 40 40h240c22.002 0 40-17.998 40-40V226c0-22.002-17.998-40-40-40zM256 368c-22.002 0-40-17.998-40-40s17.998-40 40-40 40 17.998 40 40-17.998 40-40 40zm62.002-182H193.998v-40c0-34.004 28.003-62.002 62.002-62.002 34.004 0 62.002 27.998 62.002 62.002v40z"></path></svg>
			</div>
			<h2 className='text-xl font-medium'>Editing your chatbot flow is seamless on the desktop.</h2>
			<p className='text-base font-normal'>Editor, Integration, and Usage features only available in desktop mode.</p>
			<button onClick={handleBackClick} type='button' className='bg-[#5973F7] text-white text-sm text-center rounded-md p-3 w-20'>Back</button>
    </div>
  )
}

export default RestrictEditor