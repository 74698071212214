import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allowSave : false,
    showPreview : true,
    allowSaveAndTrain : false,
}



const globalSaveSlice = createSlice({
    name : "globalSave",
    initialState,
    reducers : {

        setAllowSave : (state, action) => {
            state.allowSave = action.payload
        },
        setShowPreview : (state, action) => {
            state.showPreview = action.payload
        },
        setAllowSaveAndTrain : (state, action) => {
            state.allowSaveAndTrain = action.payload
        }
    }
})


export const { setAllowSave  , setShowPreview , setAllowSaveAndTrain} = globalSaveSlice.actions
export default globalSaveSlice.reducer