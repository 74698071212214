import React, { useState, useEffect } from 'react';
import { stopLoader } from '../../utilities/chatbotMethods';

const WindowResizeNotify = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    stopLoader("*")
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div>
      <p>Window Width: {windowWidth}</p>
      {/* Your component content */}
    </div>
  );
};

export default WindowResizeNotify;
