import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getInstalledIntegrationsAsync } from "./integrationThunk"
import { updateSelectedNode } from "../botTemplate/botTemplateSlice"

const initialState = {
    installedIntegrations: [],
    dgh : 'jnhadgsvhj',
    emailTemplates : {},
    createdTemplate : null,
    selectedHandoff : ""

}




//thunk
export const getInstalledIntegrations = createAsyncThunk('integration/getInstalledIntegration', getInstalledIntegrationsAsync)


const integrationSlice = createSlice({
    name: 'integration',
    initialState,
    reducers: {
        updateSelectedHandoff : (state, action) => {
            state.selectedHandoff = action.payload
        },
        updateinstalledIntegrations: (state, action) => {
            state.installedIntegrations = action.payload
        },
        updateCreatedTemplate: (state, action) => {
            state.createdTemplate = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInstalledIntegrations.fulfilled, (state, action) => {
            console.log(action.payload, " payload")
            state.installedIntegrations = action.payload.connect_installed
            state.emailTemplates = action.payload.emailTemplates
        })
    }
})






export const {updateSelectedHandoff, updateinstalledIntegrations,updateCreatedTemplate } = integrationSlice.actions
export default integrationSlice.reducer