import { createSlice , createAsyncThunk } from "@reduxjs/toolkit"
import { getPaymentDetailsAsync } from "./paymentThunk"

const initialState = {
    subscriptionUrl : "",
    pricingPlan : null,
    comboPlan : null,
    error : null
}


//thunk
export const getPaymentDetails = createAsyncThunk('payment/getPaymentDetails' , getPaymentDetailsAsync)


const paymentSlice = createSlice({
    name : 'payment',
    initialState,
    reducers : {
        updatePaymentDetails : (state, action) => {
            state.subscriptionUrl = action.payload?.subscriptionUrl
            state.pricingPlan = action.payload?.pricingPlan
            state.comboPlan = action.payload?.comboPlan
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getPaymentDetails.fulfilled , (state , action) => {

            console.log(action.payload , "PAYLOAD IN PAYMENT SLICE")
            state.subscriptionUrl = action.payload?.subscriptionUrl
            state.pricingPlan = action.payload?.pricingPlan
            state.comboPlan = action.payload?.comboPlan
            state.error  = null
        }).addCase(getPaymentDetails.rejected , (state , action) => {

            console.log(action.payload , "PAYLOAD IN PAYMENT SLICE")
            state.subscriptionUrl = ""
            state.pricingPlan = null
            state.comboPlan = null
            state.error = action.payload
            
        })
    }
})



export const { updatePaymentDetails} = paymentSlice.actions 

export default paymentSlice.reducer